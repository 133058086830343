<script setup lang="ts">
const signWithX = async () => {
    try {
        let twitterResponse = await apiService.getTwitterLoginUrl();
        const {redirectUrl} = twitterResponse.data;

        window.location.href = redirectUrl;
    } catch (error) {
        console.error(error);
    }
};
</script>

<template>
    <button class="text-sm" @click="signWithX">
        <slot>
            Sign up via
            <IconsTweetIcon/>
        </slot>
    </button>
</template>